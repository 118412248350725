<template>
  <div>
    <div class="container chat-builder-container">
      <div class="row">
        <div class="chat-canvas col-8">
          {{ nodeID }}
          <div v-if="chatMessages.length === 0">No chat yet!</div>
          <draggable
            v-model="chatMessages"
            group="people"
            v-if="chatMessages"
            @end="onEnd"
          >
            <div
              v-for="(chat, index) in chatMessages"
              :key="chat.id + Math.random()"
              class="chat-bubble row"
            >
              <template v-if="chat.type === 'decision'">
                <div class="col-9">
                  Player input - {{ chat.chatRoom }}<br />
                  Timer: {{ chat.timer }}<br />
                  Has Fact Check: {{ (chat.image.useImage) ? 'Yes' : 'No' }} <br />
                  <table border="1" cellpadding="4">
                    <tr>
                      <td align="center" width="100">Option</td>
                      <td align="center" width="100">Point</td>
                      <td align="center" width="100">Next Script</td>
                    </tr>
                    <template v-for="option in chat.decisionOptions">
                      <tr v-bind:key="option.id">
                        <td v-html="formatMessage(option.answer)"></td>
                        <td>{{ option.points }}</td>
                        <td>{{ getScriptTitle(option.nextNode) }}</td>
                      </tr>
                    </template>
                  </table>
                </div>
              </template>
              <template v-if="chat.type === 'scenario'">
                <div class="col-9">
                  {{ chat.type }}
                  <div class="mb-3" v-for="(scenario, index) in chat.scenarioOptions" v-bind:key="index + 'scenario'">
                    <div><img :src="$data_get(scenario, 'image_mobile')" class="img-fluid" /></div>
                    <template v-if="scenario.nextNode">
                      Next Node:
                      {{ nodeOptions.find(option => option.value == scenario.nextNode ).text }}

                    </template>
                  </div>
                </div>
              </template>
              <template v-else-if="chat.chatType === 'article'">
                <div class="col-9 article">
                  <div>
                    {{ chat.user }} - {{ chat.chatRoom }} -
                    {{ chat.chatType }}
                  </div>
                  <div class="d-flex">
                    <b-img fluid :src="chat.image.thumb" />
                    <span>{{ chat.image.title }}</span>
                  </div>
                  <div v-html="formatMessage(chat.message)"></div>
                  <div>Timer: {{ chat.timer }}</div>
                </div>
              </template>
              <template v-else-if="chat.type === 'event'">
                <div class="col-9">
                  <div>Event:</div>
                  <div>{{ grabChatEventLabel(chat.eventOption.type) }}</div>
                  <template v-if="chat.eventOption.type === 'score'">
                    <div class="score-wrapper">
                      <div>{{ userSettings['your-score'] }} </div>
                      <div class="score">
                        <div class="sticker">
                          <img src="@/assets/images/happy-smile.png" />
                        </div>
                        <div class="number">
                          90%
                        </div>
                        <div class="button">
                          <img src="@/assets/images/repeat.png" />
                          <div>{{ userSettings.play }} {{ userSettings.again }}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="chat.eventOption.type === 'cek-literata'">
                    <div class="share-wrapper">
                      <div>{{ userSettings['share-title'] }}</div>
                      <div class="share-article">
                        <div class="image"></div>
                        <div class="title">{{ userSettings['share-description'] }}</div>
                        <div class="link" v-html="userSettings['share-subtitle']"></div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="chat.eventOption.type === 'interstital'">
                    <div style="margin-top:20px"><b>Timer:</b> {{ chat.timer }}</div>
                    <div><b>Type:</b> {{ chat.eventOption.value }}</div>
                    <div v-if=" chat.eventOption.value === 'correct'"><b>Badge:</b> {{ chat.eventOption.badge }}</div>
                    <div><b>Content:</b></div>
                    <div v-html="formatMessage(chat.message)"></div>
                  </template>
                  <template v-else-if="chat.eventOption.type === 'progress-bar'">
                    <div><b>Type:</b>{{ chat.eventOption.value }}</div>
                  </template>
                  <template v-else-if="chat.eventOption.type === 'avatar-selection'">
                    <div>Timer: {{ chat.timer }}</div>
                  </template>
                  <template v-else>
                    <div>{{ getScriptTitle(chat.eventOption.value) }}</div>
                    <div v-if="chat.eventOption.type === 'end-game'">
                      This event indicate the game is ending and will redirect player to end game chapter
                      depending what score they got
                    </div>
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="col-9">
                  <div>
                    {{ chat.user }} - {{ chat.chatRoom }} -
                    {{ chat.chatType }}
                  </div>
                  <div v-if="chat.chatType == 'sticker'">
                    <Stickers :stickerName="chat.sticker.toString()"> </Stickers>
                  </div>
                  <div v-if="chat.chatType == 'image'">
                    <b-img fluid :src="chat.image.thumb" />
                    <!-- <button @click.prevent="formConfig.previewMedia=chat.image.full.toString()" v-b-modal.preview-media>see full</button> -->
                  </div>
                  <div v-html="formatMessage(chat.message)"></div>
                  <div>Timer: {{ chat.timer }}</div>
                </div>
              </template>
              <div class="action">
                <button class="duplicate" @click.prevent="duplicateMessage(index, chat)">
                  D
                </button>
                <button class="edit" @click.prevent="editMessage(index, chat)">
                  <img src="@/assets/images/edit.png" />
                </button>
                <button class="delete" v-on:click="deleteMessage(index)">
                  <img src="@/assets/images/icon-delete.png" />
                </button>
              </div>
            </div>
          </draggable>
        </div>
        <div class="toolbox col-4">
          <b-form>
            <b-form-group
              class="text-left"
              label="Script Chapter:"
            >
              <b-form-select
                :options="nodeOptions"
                v-model="nodeID"
                :value="null"
                v-on:change="refreshEditor"
              ></b-form-select>

              <b-form-input
                class="mt-1"
                placeholder="maximum points"
                v-model="max_points"
                v-on:blur="saveMaxPoints()"
              >
              </b-form-input>

               <b-form-input
                class="mt-1"
                placeholder="maximum progress"
                v-model="max_progress"
                v-on:blur="saveMaxPoints()"
              >
              </b-form-input>
              <div v-html="firstScript"></div>
            </b-form-group>
            <div>
              <b-button variant="primary" class="mb-2" @click="openNodeModal('new')">New Chapter</b-button>
              &nbsp; &nbsp;
              <b-button variant="info" class="mb-2" @click="openNodeModal('edit')">Edit Chapter</b-button>
              &nbsp; &nbsp;
              <b-button variant="danger"  class="mb-2" @click="deleteNode('delete')">Delete Chapter</b-button>
              &nbsp; &nbsp;
            </div>
          </b-form>
          <hr />
          <div class="row">
            <div class="col-12">
              <h4>Add new chat bubble:</h4>
            </div>
            <div class="tool col-12 ">
              <div @click="openMessageModal('group')">
                <h6>Chat Message</h6>
              </div>
            </div>

            <div class="tool col-12 ">
              <div @click="openDecisionModal()">
                <h6>Decision</h6>
              </div>
            </div>
            <div class="tool col-12 ">
              <div @click="openScenarioModal()">
                <h6>Scenario</h6>
              </div>
            </div>

            <div class="tool col-12">
              <div @click="openEventModal()">
                <h6>Event</h6>
              </div>
            </div>
            <GameSettings />

            <div class="tool col-12 ">
              <hr />
            </div>
            <div class="tool col-12">
              <b-button variant="warning" class="mr-2" @click="syncChat()">Sync Scripts</b-button>
              <b-button variant="info" @click="$router.push('/')">View Live Game</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal id="modal-1" hide-footer size="lg" title="">
      <b-form @submit="addNewMessage">
        <b-form-group label="User:" description="This Chat bubble is for user?">
          <b-form-select
            v-model="form.user"
            :options="userOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Room:"
          description="Chat room this message / status goes to"
        >
          <chat-room-select v-model="form.chatRoom" ></chat-room-select>
        </b-form-group>
        <b-form-group label="Chat Type:" description="What is this chat type?">
          <b-form-select
            v-model="form.chatType"
            :options="chatOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Timer Delay:"
          description="How long this chat bubble appears for, before the new chat bubble shows?"
        >
          <b-form-select v-model="form.timer" size="sm">
            <b-form-select-option :value="500">0.5s</b-form-select-option>
            <b-form-select-option :value="1000">1s</b-form-select-option>
            <b-form-select-option :value="1500">1.5s</b-form-select-option>
            <b-form-select-option :value="2000">2s</b-form-select-option>
            <b-form-select-option :value="2500">2.5s</b-form-select-option>
            <b-form-select-option :value="3000">3s</b-form-select-option>
            <b-form-select-option :value="3500">3.5s</b-form-select-option>
            <b-form-select-option :value="4000">4s</b-form-select-option>
            <b-form-select-option :value="4500">4.5s</b-form-select-option>
            <b-form-select-option :value="5000">5s</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <template v-if="form.chatType === 'image'">
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.thumb" fluid />
            </div>
            <input type="text" class="d-block mt-2 mb-2" v-if="form.image.thumb" v-model="form.image.thumb_caption" placeholder="alt title" />
            <button @click.prevent="openModalMedia('thumb')">
              Thumb Image
            </button>
            <template v-if="form.image.thumb">
              <button @click.prevent="form.image.thumb = ''">Remove</button>
            </template>
          </b-form-group>
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.full" fluid />
            </div>
            <input type="text" class="d-block" v-if="form.image.full" v-model="form.image.full_caption" placeholder="alt title" />
            <button @click.prevent="openModalMedia('full')">
              Full Image (mobile)
            </button>
            <template v-if="form.image.full">
              <button @click.prevent="form.image.full = ''">Remove</button>
            </template>
          </b-form-group>
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.tablet" fluid />
            </div>
            <input type="text" class="d-block mt-2 mb-2" v-if="form.image.tablet" v-model="form.image.tablet_caption" placeholder="alt title" />
            <button @click.prevent="openModalMedia('tablet')">
              Full Image (Tablet)
            </button>
            <template v-if="form.image.tablet">
              <button @click.prevent="form.image.tablet = ''">Remove</button>
            </template>
          </b-form-group>
        </template>
        <template v-if="form.chatType === 'article'">

          <b-form-group label="Article Title:" description="What is the article title?">
            <b-form-input v-model="form.image.title"></b-form-input>
          </b-form-group>
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.thumb" fluid />
            </div>
            <input type="text" class="d-block" v-if="form.image.thumb" v-model="form.image.thumb_caption" placeholder="alt title" />
            <button @click.prevent="openModalMedia('thumb')">
              Thumb Image
            </button>
            <template v-if="form.image.thumb">
              <button @click.prevent="form.image.thumb = ''">Remove</button>
            </template>
          </b-form-group>
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.full" fluid />
            </div>
            <input type="text" class="d-block mt-2 mb-2" v-if="form.image.full" v-model="form.image.full_caption" placeholder="alt title" />
            <button @click.prevent="openModalMedia('full')">
              Full Image (mobile)
            </button>
            <template v-if="form.image.full">
              <button @click.prevent="form.image.full = ''">Remove</button>
            </template>
          </b-form-group>
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.tablet" fluid />
            </div>
            <input type="text" class="d-block mt-2 mb-2" v-if="form.image.tablet" v-model="form.image.tablet_caption" placeholder="alt title" />
            <button @click.prevent="openModalMedia('tablet')">
              Full Image (Tablet)
            </button>
            <template v-if="form.image.tablet">
              <button @click.prevent="form.image.tablet = ''">Remove</button>
            </template>
          </b-form-group>
        </template>
        <template v-if="form.chatType === 'sticker'">
          <div>
            <div v-if="form.sticker">
              <Stickers :stickerName="form.sticker.toString()" :key="formConfig.stickerID"></Stickers>
            </div>
            <Stickers @select="selectSticker"></Stickers>
            <button
              @click.prevent="formConfig.stickerManager = !formConfig.stickerManager"
            >
              Select Sticker
            </button>
          </div>
        </template>
        <b-form-group label="Message:" description="available tags: [ul][ol][li][br][blue][link][i][b][center][url]">
          <VueEmoji ref="emoji" @input="form.message = $event.data" :value="form.message" />
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-modal>
    <!-- Modal Event -->
    <b-modal id="modal-event" hide-footer size="lg" title="">
      <template v-if="form.type === 'event'">
        <b-form @submit.prevent="addEvent" @reset="onReset">
          <b-form-group
            label="Event type:"
            description="What kind of event type you are triggering"
          >

            <b-form-select v-model="form.eventOption.type" size="sm">
              <b-form-select-option value="redirect">Change Script</b-form-select-option>
              <b-form-select-option value="progress-bar">Add Progress</b-form-select-option>
              <b-form-select-option value="end-game">End Game</b-form-select-option>
              <b-form-select-option value="score">Show Score</b-form-select-option>
              <b-form-select-option value="interstital">Interstital</b-form-select-option>
              <b-form-select-option value="cek-literata">Final CTA Redirect</b-form-select-option>
              <b-form-select-option value="avatar-selection">Avatar Selection</b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Room:"
            description="Chat room this message / status goes to"
          >
            <chat-room-select v-model="form.chatRoom" ></chat-room-select>
          </b-form-group>
          <template v-if="form.eventOption.type === 'redirect'">
            <b-form-group
              label="Next Script:"
              description="Where is this redirect script go?"
            >
              <b-form-select
                v-model="form.eventOption.value"
                :options="nodeOptions"
                size="sm"
              >
              </b-form-select>
            </b-form-group>
          </template>
          <template v-if="form.eventOption.type === 'avatar-selection'">
            <b-form-group
              label="Timer Delay:"
              description="How long this chat bubble appears for, before the new chat bubble shows?"
            >
              <b-form-select v-model="form.timer" size="sm">
                <b-form-select-option :value="500">0.5s</b-form-select-option>
                <b-form-select-option :value="1000">1s</b-form-select-option>
                <b-form-select-option :value="1500">1.5s</b-form-select-option>
                <b-form-select-option :value="2000">2s</b-form-select-option>
                <b-form-select-option :value="2500">2.5s</b-form-select-option>
                <b-form-select-option :value="3000">3s</b-form-select-option>
                <b-form-select-option :value="3500">3.5s</b-form-select-option>
                <b-form-select-option :value="4000">4s</b-form-select-option>
                <b-form-select-option :value="4500">4.5s</b-form-select-option>
                <b-form-select-option :value="5000">5s</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </template>
          <template v-if="form.eventOption.type === 'interstital'">
            <b-form-group
              label="Type:"
              description="wrong/right"
            >
              <b-form-select
                v-model="form.eventOption.value"
                size="sm"
              >
                <b-form-select-option value="correct">Correct</b-form-select-option>
                <b-form-select-option value="wrong">Wrong</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Timer Delay:"
              description="How long this chat bubble appears for, before the new chat bubble shows?"
            >
              <b-form-select v-model="form.timer" size="sm">
                <b-form-select-option :value="500">0.5s</b-form-select-option>
                <b-form-select-option :value="1000">1s</b-form-select-option>
                <b-form-select-option :value="1500">1.5s</b-form-select-option>
                <b-form-select-option :value="2000">2s</b-form-select-option>
                <b-form-select-option :value="2500">2.5s</b-form-select-option>
                <b-form-select-option :value="3000">3s</b-form-select-option>
                <b-form-select-option :value="3500">3.5s</b-form-select-option>
                <b-form-select-option :value="4000">4s</b-form-select-option>
                <b-form-select-option :value="4500">4.5s</b-form-select-option>
                <b-form-select-option :value="5000">5s</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              v-if="form.eventOption.value === 'correct'"
              label="Badge:"
              description="Badge to collect"
            >
              <template v-if="form.eventOption.badge">
                <template v-if="$store.getters['badges/getFromSlug'](form.eventOption.badge)">
                  <img :src="$store.getters['badges/getFromSlug'](form.eventOption.badge).url" />
                </template>
                <button @click="form.eventOption.badge = null">Clear</button>
              </template>
              <b-form-select
                v-model="form.eventOption.badge"
                :options="$store.getters['badges/getAsOption']"
                size="sm"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="form.eventOption.value === 'wrong'"
              label="Badge:"
              description="Badge to collect"
            >
              <template v-if="form.eventOption.badge">
                <template v-if="$store.getters['badges/getFromSlug'](form.eventOption.badge)">
                  <img :src="$store.getters['badges/getFromSlug'](form.eventOption.badge).url" />
                </template>
                <button @click="form.eventOption.badge = null">Clear</button>
              </template>
              <b-form-select
                v-model="form.eventOption.badge"
                :options="$store.getters['badges/getAsOption']"
                size="sm"
              >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Message:" description="available tags: [ul][ol][li][br][blue][link][i][b][center]">
              <VueEmoji ref="emoji" @input="form.message = $event.data" :value="form.message" />
            </b-form-group>
          </template>
          <template v-if="form.eventOption.type === 'progress-bar'">
            <b-form-group
              label="Action:"
              description="add/reset"
            >
              <b-form-select
                v-model="form.eventOption.value"
                :options="[
                  {
                    text: 'Add',
                    value: 'add'
                  },
                  {
                    text: 'Reset',
                    value: 'reset'
                  }
                ]"
                size="sm"
              >
              </b-form-select>
            </b-form-group>
          </template>
          <b-button type="submit" variant="primary">Submit</b-button>
        </b-form>
      </template>
    </b-modal>
    <!-- Modal Decision -->
    <b-modal id="modal-decision" hide-footer size="lg" title="">
      <b-form @submit="addDecision" @reset="onReset">
        <b-form-group
          label="Room:"
          description="Chat room this message / status goes to"
        >
          <chat-room-select v-model="form.chatRoom" ></chat-room-select>
        </b-form-group>
        <div class="d-flex">
          <button @click.prevent="addDecisionOption()">
            Add new Decision Option
          </button>
        </div>
        <div
          class="form-group row"
          v-for="(input, k) in form.decisionOptions"
          :key="k"
        >
          <div class="col-4">
            <div>Answer</div>
            <VueEmoji ref="emoji" @input="input.answer = $event.data" :value="input.answer" />
          </div>
          <div class="col-4">
            <div>Points</div>
            <input
              type="text"
              class="form-control"
              placeholder="Points"
              v-model="input.points"
            />
          </div>
          <div class="col-3">
            <div>Next Script</div>
            <b-form-select
              v-model="input.nextNode"
              :options="nodeOptions"
              size="sm"
            >
              <b-form-select-option :value="-1" selected
                >Stay Here</b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-1">
            <a href="#" @click.prevent="removeDecisionOption(k)">
              <img src="@/assets/images/icon-delete.png" style="width: 30px" />
            </a>
          </div>
          <div class="col-12">
            <div>Long Answer</div>
            <VueEmoji ref="emoji" @input="input.long_message = $event.data" :value="input.long_message" />
          </div>
        </div>
        <div>
          <input type="checkbox" v-model="form.image.useImage"> Has fact check
        </div>
        <!-- fact check -->
        <div v-if="form.image.useImage">
          <b-form-group
            label="Caption:"
            description="Title in the fact check notification area">
            <b-form-input type="text" v-model="form.image.caption"></b-form-input>
          </b-form-group>
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.full" fluid />
            </div>
            <button @click.prevent="openModalMedia('full')">
              Full Image (mobile)
            </button>
            <template v-if="form.image.full">
              <button @click.prevent="form.image.full = ''">Remove</button>
            </template>
          </b-form-group>
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.tablet" fluid />
            </div>
            <button @click.prevent="openModalMedia('tablet')">
              Full Image (Tablet)
            </button>
            <template v-if="form.image.tablet">
              <button @click.prevent="form.image.tablet = ''">Remove</button>
            </template>
          </b-form-group>
        </div>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-modal>
    <!-- Modal scenario -->
    <b-modal id="modal-scenario" hide-footer size="lg" title="">
      <b-form @submit="addScenario" @reset="onReset">
        <b-form-group
          label="Room:"
          description="Chat room this message / status goes to"
        >
          <chat-room-select v-model="form.chatRoom" ></chat-room-select>
        </b-form-group>
        <div class="d-flex">
          <button @click.prevent="addScenarioOption()">
            Add new Scenario Option
          </button>
        </div>
        <div
          class="form-group row"
          v-for="(input, k) in form.scenarioOptions"
          :key="k"
        >
          <div class="col-4">
            Image Mobile:
            <image-picker v-model="input.image_mobile"></image-picker>
          </div>

          <div class="col-4">
            Image Desktop:
            <image-picker v-model="input.image_desktop"></image-picker>
          </div>
          <div class="col-3">
            <div>Next Script</div>
            <b-form-select
              v-model="input.nextNode"
              :options="nodeOptions"
              size="sm"
            >
              <b-form-select-option :value="-1" selected
                >Stay Here</b-form-select-option
              >
            </b-form-select>
            <b-form-group>
              <label class="mb-0 mt-1">Title</label>
              <b-input v-model="input.title" placeholder="Title used for GTM tracking"></b-input>
            </b-form-group>

          </div>
          <div class="col-1">
            <a href="#" @click.prevent="removeScenarioOption(k)">
              <img src="@/assets/images/icon-delete.png" style="width: 30px" />
            </a>
          </div>
        </div>
        <!-- fact check -->
        <div v-if="form.image.useImage">
          <b-form-group
            label="Caption:"
            description="Title in the fact check notification area">
            <b-form-input type="text" v-model="form.image.caption"></b-form-input>
          </b-form-group>
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.full" fluid />
            </div>
            <button @click.prevent="openModalMedia('full')">
              Full Image (mobile)
            </button>
            <template v-if="form.image.full">
              <button @click.prevent="form.image.full = ''">Remove</button>
            </template>
          </b-form-group>
          <b-form-group>
            <div class="limitPreviewMedia">
              <b-img :src="form.image.tablet" fluid />
            </div>
            <button @click.prevent="openModalMedia('tablet')">
              Full Image (Tablet)
            </button>
            <template v-if="form.image.tablet">
              <button @click.prevent="form.image.tablet = ''">Remove</button>
            </template>
          </b-form-group>
        </div>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-modal>
    <!-- Loading Screen -->
    <div class="loading" v-if="globalLoading">
      <div class="content">
        <b-spinner variant="danger"></b-spinner>
        <div class="message">{{ globalLoadingMessage }}</div>
      </div>
    </div>
    <!-- modal media -->
    <b-modal id="modal-media" hide-footer size="xl" title="">
      <MediaManager @select="assetSelect" />
    </b-modal>
    <!-- preview Media -->
    <b-modal id="preview-media" hide-footer size="lg" title="">
      <b-img :src="formConfig.previewMedia" fluid />
    </b-modal>
    <!-- new Question / node -->
    <b-modal id="node-form" hide-footer size="lg" title="">
      <b-form-group
        label="Script Title:"
        description="Give an easy to remember title for this script. i.e Question #1"
      >
        <b-form-input v-model="nodeForm.title" size="sm"></b-form-input>
      </b-form-group>
      <b-form-group
        label="Set as the first script?"
        description="Set this chapter as the first script when player enter and initially start the game"
      >
        <b-form-checkbox value="1" v-model="nodeForm.is_home" size="sm">First Script</b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Preload script?"
        description="Check this so all chat message in this script will be preloaded on initial game start"
      >
        <b-form-checkbox value="1" v-model="nodeForm.preload_script" size="sm">Preload chat on script</b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Set as the end script?"
        description="Set this chapter as the end script, so when an event of endgame appear, this chapter will be set"
      >
        <b-form-checkbox value="1" v-model="nodeForm.is_end" size="sm">This is end script</b-form-checkbox>
      </b-form-group>
      <template v-if="nodeForm.is_end">
        <!-- <b-form-group label="Which score category this end game is?">
          <b-form-radio v-model="nodeForm.end_score" name="some-radios" value="A">A: Perfect Score: 100 pt</b-form-radio>
          <b-form-radio v-model="nodeForm.end_score" name="some-radios" value="B">B: Score: 76-99 pt</b-form-radio>
          <b-form-radio v-model="nodeForm.end_score" name="some-radios" value="C">C: Score: 50-75 pt</b-form-radio>
          <b-form-radio v-model="nodeForm.end_score" name="some-radios" value="D">D: Score: 25-49 pt</b-form-radio>
          <b-form-radio v-model="nodeForm.end_score" name="some-radios" value="E">E: Score: 0-24 pt</b-form-radio>
        </b-form-group> -->
        <b-form-group label="Which score category this end game is?">
          <b-form-radio v-model="nodeForm.end_score" name="some-radios" value="A">A: Perfect Score: 8 pt</b-form-radio>
          <b-form-radio v-model="nodeForm.end_score" name="some-radios" value="B">B: Score: 5-7 pt</b-form-radio>
          <b-form-radio v-model="nodeForm.end_score" name="some-radios" value="C">C: Score: 4 pt</b-form-radio>
          <b-form-radio v-model="nodeForm.end_score" name="some-radios" value="D">D: Score: 0-3 pt</b-form-radio>
        </b-form-group>
      </template>
      <b-button type="submit" @click.prevent="createNewNode()" variant="primary"
        >Submit</b-button
      >
    </b-modal>
  </div>
</template>
<!-- /* eslint-disable */ -->
<script>
import draggable from 'vuedraggable'
import { uuid } from 'vue-uuid'
import MediaManager from '@/components/MediaManager'
import Stickers from '@/components/Stickers'
import VueEmoji from 'emoji-vue'
import Base from '@/mixins/base.js'
import ChatRoomSelect from '../components/ChatRoomSelect.vue'
import ImagePicker from '../components/ImagePicker.vue'
import GameSettings from '../components/GameSettings.vue'

export default {
  components: {
    draggable,
    MediaManager,
    Stickers,
    VueEmoji,
    ChatRoomSelect,
    ImagePicker,
    GameSettings
  },
  mixins: [
    Base
  ],
  computed: {
    firstScript: function () {
      var firstScript = null
      var endScore = {
        a: '-', b: '-', c: '-', d: '-', e: '-'
      }

      for (var i = 0; i < this.nodeOptions.length; i++) {
        if (this.nodeOptions[i].is_home === '1') {
          firstScript = this.nodeOptions[i].text
        }
        if (this.nodeOptions[i].is_end === '1') {
          if (this.nodeOptions[i].end_score === 'A') {
            endScore.a = this.nodeOptions[i].text
          } else if (this.nodeOptions[i].end_score === 'B') {
            endScore.b = this.nodeOptions[i].text
          } else if (this.nodeOptions[i].end_score === 'C') {
            endScore.c = this.nodeOptions[i].text
          } else if (this.nodeOptions[i].end_score === 'D') {
            endScore.d = this.nodeOptions[i].text
          } else if (this.nodeOptions[i].end_score === 'E') {
            endScore.e = this.nodeOptions[i].text
          }
        }
      }

      return 'First Script: ' + firstScript + '<br />' +
              '8   : ' + endScore.a + '<br />' +
              '5-7 : ' + endScore.b + '<br />' +
              '4 : ' + endScore.c + '<br />' +
              '0-3 : ' + endScore.d + '<br />'

      /* return 'First Script: ' + firstScript + '<br />' +
              '100   : ' + endScore.a + '<br />' +
              '76-99 : ' + endScore.b + '<br />' +
              '50-75 : ' + endScore.c + '<br />' +
              '25-49 : ' + endScore.d + '<br />' +
              ' 0-24 : ' + endScore.e + '<br />' */
    }
  },
  watch: {
    userSettings: function () {
      // this.userOptions[0].text = this.userSettings.ayah
      // this.userOptions[1].text = this.userSettings.ibu
      // this.userOptions[2].text = this.userSettings.om
      // this.userOptions[3].text = this.userSettings.tante
      // this.userOptions[4].text = this.userSettings.adik
      // this.userOptions[5].text = this.userSettings.eka
      // this.userOptions[6].text = this.userSettings.abang
    },
    'form.eventOption.value': function (oldVal, newVal) {
      console.log('here', newVal)
      if (newVal === 'wrong') {
        this.form.eventOption.badge = 'wrong-badge'
      }
    }
  },
  data () {
    return {
      chatOptions: [
        {
          value: 'plain',
          text: 'Plain Chat'
        },
        {
          value: 'image',
          text: 'Image'
        },
        {
          value: 'sticker',
          text: 'Sticker'
        },
        {
          value: 'article',
          text: 'Article'
        }
        // {
        //   value: 'status',
        //   text: 'Chat Status'
        // }
      ],
      nodeOptions: [],
      nodeID: null,
      max_points: '',
      max_progress: '',
      nodeForm: {
        id: null,
        title: '',
        is_home: false,
        is_end: false,
        end_score: ''
      },
      myText: '',
      form: {
        id: '', // unique message id
        user: {
          name: '',
          url: ''
        },
        timer: '', // timer of this bubble should show up after previous chat bubble
        type: '', // either chat or decision or event
        chatType: '', // image / sticker / status / message
        chatRoom: '', // either group or personal
        sticker: false,
        image: {
          useImage: false,
          thumb: '',
          full: '',
          tablet: '',
          title: ''
        },
        message: '', // plain message
        decisionOptions: [
          {
            id: '',
            answer: '',
            points: '',
            long_message: '',
            nextNode: ''
          }
        ],
        scenarioOptions: [
          {
            image: {
              mobile: null,
              desktop: null
            }
          }
        ],
        eventOption: { // for redirect or end game
          type: '',
          value: ''
        }
      },
      chatMessages: [],
      allNodes: [],
      globalLoading: true,
      globalLoadingMessage: '',
      formConfig: {
        stickerManager: false
      } // any temporary variable
    }
  },
  mounted () {
    this.getBaseUrl().then(() => {
      this.initialLoad()
      this.getMaxPoint()
    })
  },
  methods: {
    /**
     * dumb way to change cek-literata
     * to final-cta-redirect
     */
    grabChatEventLabel (eventName) {
      if (eventName === 'cek-literata') {
        return 'Final CTA Redirect'
      }

      return eventName
    },

    saveMaxPoints () {
        const args = {
          action: 'save_max_point',
          max_point: this.max_points,
          max_progress: this.max_progress
        }
        this.makeCall(args).then(res => {
          // alert('Saved!')
        })
    },

    getMaxPoint () {
        const args = {
          action: 'get_max_point',
          max_point: this.max_points
        }
        this.makeCall(args).then(res => {
          this.max_points = res.data.max_point
          this.max_progress = res.data.max_progress
        })
    },

    onInput (target, event) {
      if (target === 'form.message') {
        this.form.message = event.data
      }
    },
    getScriptTitle (nodeID) {
      if (typeof nodeID !== 'undefined') {
        if (nodeID == null) {
          return ''
        }
        if (nodeID.toString() === '-1') {
          return 'Stay Here'
        }
        for (var i = 0; i < this.nodeOptions.length; i++) {
          try {
            if (this.nodeOptions[i].value.toString() === nodeID.toString()) {
              return this.nodeOptions[i].text
            }
          } catch (err) {
            return this.nodeOptions[i]
          }
        }
      }
      return ''
    },
    /**
     * Sticker
     */
    selectSticker (index) {
      this.formConfig.stickerManager = false
      this.form.sticker = index
      this.formConfig.stickerID = uuid.v1()
      // console.log(this.formConfig)
    },
    openStickersSet () {
      this.formConfig.stickerManager = true
    },
    /**
     * modal media
     */
    openModalMedia (target, obj = null) {
      if (obj) {
        this.formConfig.targetObj = obj
      }

      this.formConfig.target = target
      this.$bvModal.show('modal-media')
    },

    /**
     * callback once user selecting asset
     */
    assetSelect (url) {
      console.log(this.formConfig.targetObj)
      // this.formConfig.target = url
      if (this.getField(this.formConfig, 'targetObj', false)) {
        this.formConfig.targetObj = null
      } else {
        this.form.image[this.formConfig.target] = url
      }
      this.$bvModal.hide('modal-media')
    },
    /**
     * delete Node
     */
    deleteNode () {
      if (confirm('Are you sure to delete?')) {
        const args = {
          action: 'delete_node',
          post_id: this.nodeID
        }
        this.makeCall(args).then(res => {
          alert('Deleted')
          this.loadAllNodes()
        })
      }
    },
    /**
     * new script / node section
     */
    openNodeModal (action) {
      this.$bvModal.show('node-form')
      if (action === 'edit') {
        this.nodeForm.id = this.nodeID
        for (var i = 0; i < this.nodeOptions.length; i++) {
          if (this.nodeOptions[i].value === this.nodeID) {
            this.nodeForm.title = this.nodeOptions[i].text
            this.nodeForm.is_home = this.nodeOptions[i].is_home
            this.nodeForm.is_end = (this.nodeOptions[i].is_end === '1') ? 1 : false
            this.nodeForm.end_score = this.nodeOptions[i].end_score
            this.nodeForm.preload_script = this.nodeOptions[i].preload_script
          }
        }
      } else {
        this.nodeForm.id = null
        this.nodeForm.title = ''
        this.nodeForm.is_home = 0
        this.nodeForm.is_end = 0
        this.nodeForm.end_score = ''
        this.nodeForm.preload_script = null
      }
      // console.log(this.nodeForm)
    },
    createNewNode () {
      /**
       * check Whether if we are adding or updating
       */
      this.showLoading()
      const args = {
        action: 'add_new_node',
        post_id: this.nodeForm.id,
        post_title: this.nodeForm.title,
        is_home: this.nodeForm.is_home,
        is_end: this.nodeForm.is_end,
        preload_script: this.nodeForm.preload_script,
        end_score: this.nodeForm.end_score
      }
      this.makeCall(args).then(res => {
        this.loadAllNodes()
        this.hideLoading()
      })
      this.$bvModal.hide('node-form')
    },

    /**
     * get user
     */
    getUser (id) {
      const user = this.userOptions.find(x => x.value === id)
      if (user) {
        return user.text
      }
      return ''
    },

    onEnd () {
      this.syncChat()
    },

    syncChat () {
      /**
       * POST Data to wp
       */
      this.showLoading('Sync Script')
      // console.log(JSON.stringify(this.chatMessages))
      /**
       * get all available nodes for option dropdown
       */
      const args = {
        chat_messages: (this.chatMessages),
        action: 'update_nodes',
        post_id: this.nodeID
      }
      this.makeCall(args).then(res => {
        // console.log(res.data.nodes)
        this.tree = res.data.nodes
        this.hideLoading()
      })
    },

    showLoading (message = 'loading') {
      this.globalLoading = true
      this.globalLoadingMessage = message
    },

    hideLoading () {
      this.globalLoading = false
    },

    refreshEditor () {
      /**
       * Load messages
       */
      this.showLoading()

      this.makeCall({
        post_id: this.nodeID
      }).then(response => {
        try {
          if (response.data[this.nodeID].chat_messages) {
            this.chatMessages = response.data[this.nodeID].chat_messages
          } else {
            this.chatMessages = []
          }
        } catch (e) {
          this.chatMessages = []
          // console.log(e)
        }
        this.hideLoading()
      })
    },

    loadAllNodes () {
      this.showLoading('Loading all chapters')
      const args = {
        action: 'get_all_nodes'
      }
      this.makeCall(args).then(res => {
        this.nodeOptions = res.data
        this.refreshEditor()
        this.hideLoading()
        /**
         * set the home node
         */
        if (this.nodeID === null) {
          for (var i = 0; i < this.nodeOptions.length; i++) {
            if (this.nodeOptions[i].is_home.toString() === '1') {
              this.nodeID = this.nodeOptions[i].value
            }
          }
        }
      })
    },
    initialLoad () {
      /**
       * get all available nodes for option dropdown
       */
      this.loadAllNodes()
    },

    openDecisionModal () {
      this.$bvModal.show('modal-decision')
      this.prepareFormData('decision')
    },

    openScenarioModal () {
      this.$bvModal.show('modal-scenario')
      this.prepareFormData('scenario')
    },

    openEventModal () {
      this.prepareFormData()
      this.form.type = 'event'
      this.$bvModal.show('modal-event')
    },

    addDecisionOption (index) {
      this.form.decisionOptions.push({ id: uuid.v1(), name: '' })
    },

    addScenarioOption (index) {
      this.form.scenarioOptions.push({ id: uuid.v1(), name: '' })
    },

    removeScenarioOption (index) {
      this.form.scenarioOptions.splice(index, 1)
    },

    removeDecisionOption (index) {
      this.form.decisionOptions.splice(index, 1)
    },

    openMessageModal (chatRoom) {
      // console.log(this.chatMessages)
      this.$bvModal.show('modal-1')

      this.prepareFormData()
      this.form.user = 'Ayah'
      this.form.chatRoom = chatRoom
      this.form.chatType = 'plain'
      this.form.type = 'chat'
      this.form.timer = '1000'
    },

    onReset () {},

    addToScript () {
      /**
       * check whether if we are updating or adding message
       */
      const chat = JSON.parse(JSON.stringify(this.form))
      // const chat = {}
      // Object.assign(chat, this.form)

      if (this.formConfig.editIndex !== '-1') {
        this.chatMessages.splice(this.formConfig.editIndex, 1, chat)
      } else {
        this.chatMessages.push(chat)
      }

      this.clearFormData()
      this.syncChat()
    },

    addNewMessage (event) {
      event.preventDefault()
      this.addToScript()
      /**
       * close Modal
       */
      this.$bvModal.hide('modal-1')
    },
    addEvent (event) {
      this.form.type = 'event'
      this.form.chatType = 'event'
      // this.form.chatRoom = 'group'
      if (this.form.eventOption.type === 'end-game') {
        this.form.eventOption.value = ''
      }
      if (this.form.eventOption.type === 'avatar-selection') {
        this.form.eventOption.value = ''
      }
      if (this.form.eventOption.type === 'progress-bar') {
        if (!this.form.eventOption.value) {
          this.form.eventOption.value = 'add'
        }
      }
      this.addToScript()
      this.$bvModal.hide('modal-event')
    },
    addScenario (event) {
      event.preventDefault()
      this.addToScript()
      /**
       * close Modal
       */
      this.$bvModal.hide('modal-scenario')
    },
    addDecision (event) {
      event.preventDefault()
      this.addToScript()
      /**
       * close Modal
       */
      this.$bvModal.hide('modal-decision')
    },
    duplicateMessage (index, chat) {
      this.chatMessages.splice(index, 0, JSON.parse(JSON.stringify(chat)))
      this.syncChat()
    },
    editMessage (index, chat) {
      this.form = JSON.parse(JSON.stringify(chat))
      this.formConfig.editIndex = index
      // console.log(chat)
      if (chat.type === 'decision') {
        this.$bvModal.show('modal-decision')
      } else if (chat.type === 'scenario') {
        this.$bvModal.show('modal-scenario')
      } else if (chat.type === 'event') {
        this.$bvModal.show('modal-event')
      } else {
        this.$bvModal.show('modal-1')
      }
    },
    deleteMessage (index) {
      if (confirm('Sure to delete?')) {
        this.chatMessages.splice(index, 1)
        this.syncChat()
      }
    },

    clearFormData () {
      this.form.id = null
      this.form.user = ''
      this.form.timer = ''
      this.form.type = ''
      this.form.chatType = ''
      this.form.sticker = false
      this.form.image.thumb = ''
      this.form.image.tablet = ''
      this.form.image.mobile = ''
      this.form.image.full = ''
      this.form.message = ''
      this.formConfig.editIndex = '-1'
      this.form.decisionOptions = [{}]
      this.form.eventOption = {}
      this.form.eventOption.type = ''
      this.form.scenarioOptions = [{}]
      this.form.eventOption.value = ''
    },

    prepareFormData (type) {
      this.clearFormData()

      this.form.id = uuid.v1()

      if (type === 'decision') {
        this.form.type = 'decision'
        this.form.timer = 1000
      } else if (type === 'event') {
        this.form.type = 'event'
        this.form.chatType = 'event'
      } else if (type === 'scenario') {
        this.form.type = 'scenario'
        this.form.timer = 1000
      } else {
        this.form.decisionOptions = []
      }
    }
  }
}
</script>
