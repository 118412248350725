<template>
    <div id="lottie">
        <lottie
            :options="defaultOptions"
            v-on:animCreated="handleAnimation"
            :height="'auto'"
            />
    </div>
</template>
<script>
import * as animationData from '@/assets/loading-fast.json'
import Lottie from 'vue-lottie'

export default {
  name: 'Background',
  data () {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1,
      maxWidth: 100
    }
  },
  components: {
      lottie: Lottie
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
      this.anim.setSpeed(this.animationSpeed)
    },

    stop: function () {
      this.anim.stop()
    },

    play: function () {
      this.anim.play()
    },

    pause: function () {
      this.anim.pause()
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed)
    }
  }
}
</script>
<style scoped>
    #lottie svg{
        background: #2fc7be;
    }

    #lottie{
        background-image: url('../assets/images/new-background6.jpg') !important;
        background-repeat: repeat !important;
        /* background-size: 1600px 900px !important; */
        width:100%;
        height: 100%;
        text-align: center;
        opacity: 1;
        overflow: hidden;
        display: flex;
        position: absolute;
    }

    #lottie > div {
        width: 100%  !important;
        overflow: auto !important;
    }

    #lottie svg > g > g:first-child > g > path{
      fill: #2fc7be;
    }
</style>
