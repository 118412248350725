<template>
  <div class="character-selection-wrapper">
    <div class="character-content" :class="{ 'visible' : allImagesLoaded && showAnimation }">
      <div class="row max-width-500">
        <div class="col-12 top-info-wrapper ">
          <div class="action">
            <a v-if="gameMode !== 'fast'" href="#" @click.prevent="backToWelcomeScreen">
              <img src="@/assets/images/back-icon.svg" width="10" />
            </a>
            <h2 v-html="userSettings['character-header']"></h2>
          </div>
          <charAnimation v-if="urlAnimation.animationData && setStatus" :urlAnimation="urlAnimation" />
          <img :src="selectedAvatar.url" class="rounded-circle selected-character" :class="{'hide-image' : setStatus}" />
          <h1 v-if="gameMode !== 'fast'" class="player-name">
            {{ playerName }}
          </h1>
        </div>
      </div>
      <div class="characters">
        <div class="row">
          <a href="#" v-for="avatar in avatars" v-bind:key="avatar.id" class="character col-4" :class="{ 'selected': avatar == selectedAvatar }" v-on:click.prevent="selectAvatar(avatar)">
            <img :src="avatar.url" class="rounded-circle img-fluid" @load="handleImageLoad"/>
          </a>
        </div>
      </div>
      <div class="action-button">
        <form @submit.prevent>
          <input type="button" :value="userSettings['character-button']" class="button inline yellow"  @click.prevent="playerAnswer()" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import buttonSound from '@/assets/sounds/button.wav'
import charAnimation from '@/components/CharAnimation.vue'
export default {
    name: 'characterSelection',
    components: {
      charAnimation
    },
    props: {
      storeJson: {
        type: Object,
        required: true
      },
      showAnimation: {
        type: Boolean,
        required: true
      },
      userSettings: {
        default: {},
        required: false
      },
      gameMode: {
        type: String,
        required: true
      },
      gameSkin: {
        type: String,
        required: true
      }
    },
    watch: {
      async selectedAvatar () {
          this.urlAnimation.animationData = null
          this.setStatus = false
          for (var index in this.storeJson) {
            if (this.storeJson[index].json === this.selectedAvatar.json) {
              this.urlAnimation = {
                ...this.urlAnimation,
                animationData: this.storeJson[index].file
              }
              setTimeout(() => {
                this.setStatus = true
              })
            }
            index++
          }
      }
    },
    async created () {
      this.avatars = this.$store.getters['avatars/getAvatars']
      this.$store.commit('gamePlay/setAvatar', this.avatars[0])
      try {
          if (this.storeJson[0]?.file) {
          this.urlAnimation.animationData = this.storeJson[0].file
          this.setStatus = true
        }
      } catch (err) {
        console.log(err)
      }
    },
    data () {
      return {
        setStatus: false,
        langs: ['en', 'id'],
        avatars: [],
        soundHandler: {
          button: new Audio(buttonSound)
        },
        allImagesLoaded: false,
        numLoadedImages: 0,
        totalImages: 0,
        urlAnimation: { animationData: null, autoplay: true, loop: false },
        sendAnimation: {}
      }
    },
    mounted () {
      this.totalImages = this.avatars.length

      // this.selectedAvatar = this.avatars.find(avatar => avatar.id === 2)
      // if (this.selectedAvatar) {
      //   this.selectedAvatar.selected = true
      // } else {
      //   this.selectedAvatar = this.avatars[0]
      //   this.selectedAvatar.selected = true
      // }
    },
    methods: {
      getCharacterFilename () {
        const url = this.selectedAvatar.url
        const parts = url.split('/')
        const filename = parts[parts.length - 1]
        return filename
      },
      backToWelcomeScreen () {
        this.soundHandler.button.play()
        this.$emit('backToWelcomeScreen')
      },
      playerAnswer () {
        this.$emit('characterSelection')
      },
      getImgUrl: function (imagePath) {
        return require('../assets/images/' + imagePath)
      },
      selectAvatar (avatar) {
        this.soundHandler.button.play()
        this.$store.commit('gamePlay/setAvatar', avatar)
      },
      handleImageLoad () {
        this.numLoadedImages++
        this.checkAllImagesLoaded()
      },
      checkAllImagesLoaded () {
        if (this.numLoadedImages === this.totalImages) {
          this.allImagesLoaded = true
        }
      }
    },
    computed: {
      playerName () {
        return this.$store.getters['gamePlay/getName']
      },
      selectedAvatar () {
        return this.$store.getters['gamePlay/getAvatar']
      }
    }
  }
</script>
