<template>
  <div class="end-result-wrapper">
    <div class="end-result-content">
      <div class="row">
        <div class="col-12 top-info-wrapper ">
          <h2 v-if="endMode === 'v2'" v-html="userSettings['good-job']"></h2>
          <h2 v-else>{{ playerName }}</h2>
          <img :src="selectedAvatar.url" class="rounded-circle selected-character" />
          <h5>{{ userSettings['share-you-got-a-score-of'] }}</h5>
          <div class="score-page-result-top">
            <h1 class="player-name">
              {{ $score_format(playerScore) }}
            </h1>
            <span>/{{ $score_format(max_points) }}</span>
          </div>
          <button v-if="endMode !== 'v2'" class="button inline" @click="shareClick()" v-html="userSettings['share-result']"></button>
        </div>
      </div>
      <div class="badges-wrapper">
        <template v-if="collectedBadges && collectedBadges.length == 0">
          <h6 class="center" v-html="userSettings['collected-no-badges']"></h6>
        </template>
        <template v-else>
          <h6 v-html="userSettings['collected-badges']"></h6>
        </template>
        <div class="badges">
          <div class="badge" v-for="(badge, index) in collectedBadges" v-bind:key="index + 'badges'">
            <div class="image">
              <img :src="badge.url" class="img-fluid" />
            </div>
            <div class="text">
              <h4>{{ badge.name }}</h4>
              <p>{{ badge.description }}</p>

            </div>
          </div>
        </div>
      </div>
      <div v-if="endMode === 'v2'" class="action-button">
        <input type="button" :value="userSettings['survey-button']" class="button inline yellow survey-button" @click.prevent="surveyClick()"/>
      </div>
      <div v-else class="action-button">
        <a href="" class="button inline invert" @click.prevent="aboutClick()" v-html="userSettings['about-literata']"></a>
        <input type="button" :value="userSettings['score-restart-button']" class="button inline yellow"  @click.prevent="restartGame()"/>
      </div>
    </div>
  </div>
</template>

<script>
import buttonSound from '@/assets/sounds/button.wav'

export default {
    name: 'characterSelection',
    props: {
      queryParams: {
        default: {},
        required: true
      },
      userSettings: {
        default: {},
        required: false
      }
    },
    data () {
      return {
        surveyParams: {},
        soundHandler: {
          button: new Audio(buttonSound)
        }
      }
    },
    methods: {
      restartGame () {
        // this.soundHandler.button.addEventListener('ended', () => {
        //   window.location.reload()
        // })
        this.$bus.$emit('restart-to-scenario')
        this.soundHandler.button.play()
      },
      aboutClick () {
        this.soundHandler.button.play()
        this.$bus.$emit('track-event', 'game_end_return_to_home')
        window.open('https://literata.id', '_blank')
      },
      surveyClick () {
        const urlWithParams = this.userSettings['survey-link']
        const url = new URL(urlWithParams)
        const urlParams = new URLSearchParams(url.search)

        // GET PARAMETER FROM PLUGIN
        for (const [key, value] of urlParams.entries()) {
          this.surveyParams[key] = value
        }

        // MERGE PARAMETER
        for (const key in this.queryParams) {
          if (Object.prototype.hasOwnProperty.call(this.queryParams, key)) {
            this.surveyParams[key] = this.queryParams[key]
          }
        }

        // UPDATE URL PARAMETER
        for (const key in this.surveyParams) {
          if (Object.prototype.hasOwnProperty.call(this.surveyParams, key)) {
            urlParams.set(key, this.surveyParams[key])
          }
        }
        const updatedUrl = url.origin + url.pathname + '?' + urlParams.toString()

        // REDIRECT
        this.soundHandler.button.play()
        window.open(updatedUrl, '_blank')
      },
      shareClick () {
        this.$bus.$emit('track-event', 'social_share')
        this.soundHandler.button.play()
        this.$emit('shareScreen')
      }
    },
    computed: {
      endMode () {
        return this.$store.getters['gameSetting/getEndMode']
      },
      playerName () {
        return this.$store.getters['gamePlay/getName']
      },
      selectedAvatar () {
        return this.$store.getters['gamePlay/getAvatar']
      },
      collectedBadges () {
        return this.$store.getters['gamePlay/getBadges']
      },
      playerScore () {
        return this.$store.getters['gamePlay/getScore']
      },
      max_points () {
        return this.$store.getters['gameSetting/getMaxPoint']
      }
    }
  }
</script>
