<template>
    <div v-if="userSettings['privacy_policy_popup_visible'] === 'on' && !isAgree" class="privacy-popup">
        <div class="popup-content">
            <div class="privacy-text-wrapper">
                <h6 class="privacy-title" v-html="userSettings['privacy_policy_title']"></h6>
                <p class="privacy-text" v-html="userSettings['privacy_policy_description']"></p>
            </div>
            <div class="button-group">
                <button @click="acceptPrivacy" class="btn btn-accept">{{ userSettings['privacy_policy_btn_accept_label'] }}</button>
                <a :href="userSettings['privacy_policy_btn_view_url']" class="btn btn-view" target="_blank">
                    {{ userSettings['privacy_policy_btn_view_label'] }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie'

export default {
    name: 'PrivacyPolicyPopup',
    props: {
        userSettings: {
            default: {},
            required: false
        }
    },
    data () {
        return {
            isAgree: false
        }
    },
    mounted () {
        this.isAgree = (typeof Cookies.get('moonshot_game_privacy_policy') !== 'undefined') ? 1 : 0
        this.$emit('privacy_policy_is_agree', this.isAgree)
    },
    methods: {
        acceptPrivacy () {
            this.isAgree = true

            Cookies.set('moonshot_game_privacy_policy', this.isAgree, { expires: 365 * 10 })
            this.$emit('privacy_policy_is_agree', this.isAgree)
        }
    }
}
</script>
