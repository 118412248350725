<template>
  <div>
    <LottieDescription />
    <div class="container description-page">
      <div id="game-logo-id" class="game-logo">
        <img
          class="game-logo-desc"
          :src="userSettings['game-logo']"
          :alt="userSettings['game-short']"
        />
      </div>
      <div class="description-title">
        <h2 v-html="userSettings['game-desc']"></h2>
      </div>
      <div class="description-content">
        <div class="col-lg-6 offset-lg-3 description-desc top">
          <p class="description-sub-title" v-html="userSettings['description-header-1']"></p>
          <p v-html="userSettings['description-content-1']"></p>
        </div>
        <div class="col-lg-6 offset-lg-3 description-desc bottom">
          <p class="description-sub-title" v-html="userSettings['description-header-2']"></p>
          <p v-html="userSettings['description-content-2']">
          </p>
        </div>
      </div>
    </div>
    <div class="description-button">
      <div class="container">
        <form
          @submit.prevent="playerAnswer(playerName)"
          class="description-submission"
        >
          <div class="col-lg-6 offset-lg-3">
            <input
              type="button"
              :value="userSettings['description-button']"
              class="button inline yellow"
              @click.prevent="testClick"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LottieDescription from './LottieDescription.vue'
import buttonSound from '@/assets/sounds/button.wav'

    export default {
      components: { LottieDescription },
        name: 'Description',
        props: {
        userSettings: {
            default: {},
            required: false
        }
        },
        created () {
        },
        mounted () {
        },
        data () {
        return {
            playerName: '',
            langs: ['en', 'id'],
            soundHandler: {
              button: new Audio(buttonSound)
            }
        }
        },
        methods: {
            playerAnswer (name) {
                this.$emit('playerAnswer', name)
            },
            testClick: function () {
                this.soundHandler.button.play()
                console.log('clicked!')
                this.$emit('homescreen', true)
            },
            logoClick: function () {
            window.open('https://literata.id/')
            }
        }
    }
</script>
