<template>
  <div class="end-result-outer-wrapper">
    <score-result-card v-if="!shareScreen" :queryParams="queryParams" :userSettings="userSettings" @shareScreen="shareScreen = true"></score-result-card>
    <share-result-card v-else :userSettings="userSettings" @goBack="shareScreen = false"></share-result-card>
  </div>
</template>

<script>
import ScoreResultCard from './ScoreResultCard.vue'
import ShareResultCard from './ShareResultCard.vue'

export default {
  components: { ScoreResultCard, ShareResultCard },
    name: 'endResultCard',
    props: {
      queryParams: {
        default: {},
        required: true
      },
      userSettings: {
        default: {},
        required: false
      }
    },
    data () {
      return {
        shareScreen: false
      }
    }
  }
</script>
