<template>
  <div>
    <div class="content chat-reopen-interstitial"  @click.prevent="playInterstital">
      <template
            v-if="current_chat_object.eventOption.value === 'correct'"
          >
          <a href="#" class="action-check" title="check">
            <img
                      :src="
                        $store.getters['badges/getFromSlug'](
                          $data_get(current_chat_object, 'eventOption.badge')
                        ).url
                      "
                      class="d-block reopen-badge"
                      alt="check" />
          </a>
          <p class="message center">
            <strong>
            {{ $store.getters["badges/getFromSlug"]($data_get(current_chat_object, "eventOption.badge")).name }}
            </strong>
          </p>
      </template>
      <template
        v-else-if="current_chat_object.eventOption.value === 'wrong'"
            >
            <img v-if="
                $data_get(current_chat_object, 'eventOption.badge', false)
              "
                  :src="
                    $store.getters['badges/getFromSlug'](
                      $data_get(current_chat_object, 'eventOption.badge')
                    ).url
                  "
                  class="reopen-badge"
                />
            <img v-else class="reopen-badge" src="@/assets/images/wrong-interstital-3.png">
      </template>
      <template>
        <p class="message center">{{ $data_get(userSettings, 'lihat-interstital', '-Lihat Interstitial-') }}</p>
      </template>
    </div>

    <div v-if="active" class="interstital-modal-wrapper" :class="{
      'wrong':wrong
    }">
      <div class="interstital-modal">
        <div class="head">
          <template
            v-if="current_chat_object.eventOption.value === 'correct'"
          >
            <LottieCorrect />
            <template
              v-if="
                $data_get(current_chat_object, 'eventOption.badge', false)
              "
            >
              <div class="img-wrapper">
                <BadgeAnimation v-if="hasAnimation" :animatedBadge="animationBadge" />
                <img
                  :src="
                    $store.getters['badges/getFromSlug'](
                      $data_get(current_chat_object, 'eventOption.badge')
                    ).url
                  "
                  class="d-block"
                />
              </div>
              <div class="achivement-wrapper">
                <!-- <h5>Achievement unlocked:</h5> -->
                <h5>{{ userSettings["achievement-unlocked"] }}</h5>
                <h2>
                  {{
                    $store.getters["badges/getFromSlug"](
                      $data_get(current_chat_object, "eventOption.badge")
                    ).name
                  }}
                </h2>
              </div>
            </template>
            <template v-else>
              <div class="img-wrapper">
                <img
                  src="@/assets/images/mark-good.png"
                  :title="userSettings['you-are-right']"
                  :alt="userSettings['you-are-right']"
                  class="d-block"
                />
              </div>
              <div class="achivement-wrapper">
                <h5>{{ userSettings["achievement-unlocked"] }}</h5>
                <h2>{{ userSettings["you-are-right"] }}</h2>
              </div>
            </template>
          </template>
          <template v-else>
            <LottieWrong/>
            <div class="img-wrapper">
              <BadgeAnimation v-if="hasAnimation" :animatedBadge="animationBadge ||  gameSkin !== 'sri'" />
              <div v-else>
                <BadgeWrong v-if="gameSkin !== 'sri'"  />
              </div>
              <img v-if="
                $data_get(current_chat_object, 'eventOption.badge', false)
              "
                  :src="
                    $store.getters['badges/getFromSlug'](
                      $data_get(current_chat_object, 'eventOption.badge')
                    ).url
                  "
                  :title="userSettings['you-are-wrong']"
                  :alt="userSettings['you-are-wrong']"
                  class="d-block"
                />
                <img v-else
                src="@/assets/images/wrong-interstital-3.png"
                :title="userSettings['you-are-wrong']"
                :alt="userSettings['you-are-wrong']"
                class="d-block"
              />
            </div>
            <div class="achivement-wrapper">
              <h5>{{ userSettings["header-wrong-interstital"] }}</h5>
              <h2>{{ userSettings["you-are-wrong"] }}</h2>
            </div>
          </template>
        </div>
        <div class="content reopen-badge-content" id="interstital-content">
          <div v-html="$formatMessage(current_chat_object.message)"></div>
        </div>
        <div class="action">
          <div class="container">
            <div class="col-sm-12 col-md-6 offset-md-3">
              <button
                class="button mt-3 mb-2"
                @click.prevent="closeInterstital"
                :title="userSettings['continue']"
              >
                {{ userSettings["continue"] }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import LottieCorrect from '@/components/LottieCorrect.vue'
import LottieWrong from '@/components/LottieWrong.vue'
import BadgeAnimation from '@/components/BadgeAnimation.vue'
import BadgeWrong from '@/components/BadgeWrong.vue'

export default {
  components: {
    LottieCorrect,
    LottieWrong,
    BadgeAnimation,
    BadgeWrong
  },
  props: {
    current_chat_object: {
      type: Object,
      required: true
    },
    gameSkin: {
      type: String,
      required: true
    },
    gameMode: {
      type: String,
      required: true
    },
    storeBadgeJson: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      active: false,
      animationBadge: null
    }
  },
  methods: {
    hasAnimationBadge (urlAnimationBadge) {
      for (var index in this.storeBadgeJson) {
        if (this.storeBadgeJson[index].json === urlAnimationBadge) {
          this.animationBadge = {
            ...this.animationBadge,
            animationData: this.storeBadgeJson[index].file
          }
          return true
        }
      }
      return false
    },
    checkAnimation () {
      const checkJson = this.$data_get(this.current_chat_object, 'eventOption.badge', false)
      if (checkJson) {
        this.animationBadge = this.$store.getters['badges/getFromSlug'](this.$data_get(this.current_chat_object, 'eventOption.badge')).json
        return this.animationBadge
      } else {
        return false
      }
    },
    getBadgeFilename () {
      const url = this.$store.getters['badges/getFromSlug'](this.$data_get(this.current_chat_object, 'eventOption.badge')).url
      const parts = url.split('/')
      const filename = parts[parts.length - 1]
      return filename
    },
    playInterstital () {
      this.active = true
      this.$bus.$emit('interstitial-open')
    },
    closeInterstital () {
      this.active = false
      this.$bus.$emit('interstitial-close')
    },
    formatMessage (string) {
      return string
    }
  },
  computed: {
    hasAnimation () {
      const badge = this.$data_get(this.current_chat_object, 'eventOption.badge', false)
      if (badge) {
        const urlAnimationBadge = this.$store.getters['badges/getFromSlug'](badge).json
        return this.hasAnimationBadge(urlAnimationBadge)
      } else {
        return false
      }
    },
    userSettings: function () {
      return this.$store.getters['gamePlay/getUserSettings']
    },
    wrong: function () {
      return this.$data_get(this.current_chat_object, 'eventOption.value', false) === 'wrong'
    }
  }
}
</script>
