export default {
    data () {
        return {
            baseURL: '',
            lang: 'en',
            // GTM: 'GTM-K9256RW', // production literata.id GTM code
            GTM: 'GTM-XXXXXXX',
            GTM_TRACK: false,
            userSettings: {}
            // userOptions: [
            //   {
            //     text: this.$t('Ayah'),
            //     value: 'Ayah'
            //   },
            //   {
            //     text: this.$t('ibu'),
            //     value: 'Ibu'
            //   },
            //   {
            //     text: this.$t('om'),
            //     value: 'Om'
            //   },
            //   {
            //     text: this.$t('tante'),
            //     value: 'Tante'
            //   },
            //   {
            //     text: this.$t('adik'),
            //     value: 'Adik'
            //   },
            //   {
            //     text: this.$t('eka'),
            //     value: 'Eka'
            //   },
            //   {
            //     text: this.$t('abang'),
            //     value: 'Abang'
            //   }
            // ],
            // roomOptions: [
            //   {
            //     text: this.$t('ayah'),
            //     value: 'ayah'
            //   },
            //   {
            //     text: this.$t('ibu'),
            //     value: 'ibu'
            //   },
            //   {
            //     text: this.$t('om'),
            //     value: 'om'
            //   },
            //   {
            //     text: this.$t('tante'),
            //     value: 'tante'
            //   },
            //   {
            //     text: this.$t('adik'),
            //     value: 'adik'
            //   },
            //   {
            //     text: this.$t('eka'),
            //     value: 'mentor'
            //   },
            //   {
            //     text: this.$t('abang'),
            //     value: 'abang'
            //   },
            //   {
            //     text: this.$t('Keluarga'),
            //     value: 'group'
            //   }
            // ]
        }
    },
    computed: {
      userOptions: function () {
        return this.$store.getters['actors/getAsInputOptions']
      },
      roomOptions: function () {
        return this.$store.getters['rooms/getAsInputOptions']
      }
    },
    methods: {

        /**
         * Pass a nested object and field to gracefully return the value
         * @param obj
         * @param field
         * @param default_value
         * @returns {*}
         */
          getField (obj, field, defaultValue) {
            const separator = '.'
            const properties = Array.isArray(field) ? field : field.split(separator)
            const val = properties.reduce((prev, curr) => prev && prev[curr], obj)

            if (!val) {
                return defaultValue
            }

            return val
        },

        async refresh () {
          await this.$store.dispatch('actors/get')
          this.userOptions = this.$store.getters['actors/getActors']
        },

        formatMessage (str) {
          if (str) {
            str = str.replaceAll('[blue]', '<span class="blue">')
            str = str.replaceAll('[/blue]', '</span>')

            str = str.replaceAll('[link]', '<span class="link">')
            str = str.replaceAll('[/link]', '</span>')

            str = str.replaceAll('[i]', '<i>')
            str = str.replaceAll('[/i]', '</i>')

            str = str.replaceAll('[b]', '<strong>')
            str = str.replaceAll('[/b]', '</strong>')

            str = str.replaceAll('[br]', '<br />')

            str = str.replaceAll('[ul]', '<ul>')
            str = str.replaceAll('[/ul]', '</ul>')
            str = str.replaceAll('[ol]', '<ol>')
            str = str.replaceAll('[/ol]', '</ol>')
            str = str.replaceAll('[li]', '<li>')
            str = str.replaceAll('[/li]', '</li>')

            str = str.replaceAll('[center]', '<div style="text-align: center">')
            str = str.replaceAll('[/center]', '</div>')

            str = str.replaceAll('[url]', '<a class="link-url" href="')
            str = str.replaceAll('[text]', '" target="_blank">')
            str = str.replaceAll('[/url]', '</a>')
          }

          // /**
          //  * make bold like mention to player
          //  */
          // if (this.getField(this.gameStatus, 'playerName', false)) {
          //   str = str.replace('@player', '<strong>@' + this.gameStatus.playerName + '</strong>')
          // }
          return str
      },

        getGameSlug () {
          /**
           * grab the current url SLUG
           * send it as post data to server
           *
           * .replace only find and replace first occurance
           * with regex, we replace every / in the slug
           */
          return this.$store.getters['gameSetting/getGameSlug']
        },

        /**
         * Gateway to API
         */
         async makeCall ($arg = {}) {
          // const baseURL = process.env.VUE_APP_API_URL
          const baseURL = this.baseURL

          // console.log(this.getGameSlug(), 'this')

          $arg['game-slug'] = this.getGameSlug()

          // console.log($arg, 'arg')

          const result = await this.$http
            .post(baseURL + '/moonshot-game', $arg)
            .then(response => {
              return response
            })
          return result
        },

        async wp_ajax ($args) {
          $args['game-slug'] = this.getGameSlug()
          return await this.$http
            .post(this.baseURL + '/wp-admin/admin-ajax.php', $args)
        },

        async getUserSetting () {
          const args = {
            action: 'get_user_setting'
          }
          this.makeCall(args).then(res => {
            this.userSettings = res.data
            this.$store.commit('gamePlay/setUserSettings', res.data)
          })
        },

        async getBaseUrl () {
            // const response = await this.axios.get(`${window.location.href.split('?')[0]}/config.json`)
            const config = this.$store.getters['gameSetting/getConfig']

            this.baseURL = config.API

            this.lang = 'id'
            this.$i18n.locale = this.lang
            this.$root.$i18n.locale = this.lang

            // load GTM from config
            this.GTM_TRACK = config.TRACK
            this.GTM = config.GTM

            // reset GTM ID as per config
            this.$gtm.id = this.GTM

            // hook up the user setting call
            await this.getUserSetting()
        }
    }
}
